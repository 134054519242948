<template>
  <div class="container">
    <van-address-edit
      :address-info="addressInfo"
      :area-list="areaList"
      show-postal
      :show-delete="pageStatus==='update'"
      show-set-default
      @save="onSave"
      @delete="onDelete"
    />
  </div>
</template>

<script>
import { AddressEdit } from 'vant'
import areaList from '@/common/area'

export default {
  name: 'AddressEdit',
  components: {
    [AddressEdit.name]: AddressEdit
  },
  data() {
    return {
      pageStatus: 'create', // create,update
      addressInfo: {},
      areaList: areaList,
      searchResult: []
    }
  },
  created() {
    if (this.$route.query.id) {
      this.pageStatus = 'update'
      this.$api.address_detail({ aid: this.$route.query.id }).then(res => {
        this.addressInfo = {
          id: res.data.aid,
          name: res.data.name,
          tel: res.data.phone,
          areaCode: res.data.area_code,
          province: res.data.province,
          city: res.data.city,
          county: res.data.dist,
          addressDetail: res.data.addrs,
          postalCode: res.data.poscode,
          isDefault: !!res.data.default_addr
        }
      })
    } else {
      this.pageStatus = 'create'
      this.addressInfo.isDefault = true
    }
  },
  methods: {
    onSave(form) {
      // 组件自带数据校验
      this.updateAddress(form)
    },
    updateAddress(form) {
      const apiName = this.pageStatus === 'create' ? 'address_add' : 'address_update'
      const params = {
        name: form.name,
        phone: form.tel,
        area_code: form.areaCode,
        province: form.province,
        city: form.city,
        dist: form.county,
        addrs: form.addressDetail,
        poscode: form.postalCode,
        default_addr: form.isDefault ? 1 : 0
      }
      this.pageStatus === 'update' && (params.aid = form.id)
      this.$toast.loading({
        mask: true,
        message: '地址数据提交中...',
        duration: 0
      })
      this.$api[apiName](params)
        .then(res => {
          this.$toast.success('修改成功')
          setTimeout(() => {
            this.$router.back()
          }, 1500)
        })
        .catch(err => {
          this.$toast.fail('修改失败')
          console.error(err)
        })
        .finally(() => {
          this.$toast.clear()
        })
    },
    onDelete() {
      this.$api.address_delete({ aid: this.addressInfo.id })
        .then(res => {
          this.$toast.success('删除成功')
          setTimeout(() => {
            this.$router.back()
          }, 1500)
        })
        .catch(err => {
          this.$toast.fail('删除失败')
          console.error(err)
        })
        .finally(() => {
          this.$router.go(-1)
        })
    }
  }
}
</script>

<style lang="less" scoped>

  /deep/ .van-button--danger{
    background-color: @ui-color;
    border: 1px solid @ui-color;
  }
</style>

